import { convertSchemaToHtml } from '@thebeyondgroup/shopify-rich-text-renderer'
import parse, { domToReact, HTMLReactParserOptions } from 'html-react-parser'
import { GlobalTextPreset } from '~/@types/text-preset'

import css from '~/components/Abstracts/RichText/styles.module.scss'
import { ShopifyRichTextProps } from '~/components/Abstracts/ShopifyRichText'
import InlineCta from '~/components/UI/InlineCta'

export default function htmlSerializer({
  render,
  ctaPreset,
  theme,
  withoutCtaTextStyle,
  hasLine,
  onClick,
}: ShopifyRichTextProps) {
  const options = {
    replace: ({ attribs, children, type, name }) => {
      // //find so contribution error in Shopify,
      // //Find 2 url in one, so we catch it and return an empty url like an error
      const WRONG_URL_REGEX = /https:\/\/.*https:\/\/.*/

      const hasLinkHref =
        attribs &&
        Object.keys(attribs).find((attr) => (attr === 'href' ? true : false))

      if (type === 'tag' && name === 'a' && hasLinkHref) {
        const isWrongUrlResult = Boolean(WRONG_URL_REGEX.exec(attribs.href))

        return (
          <InlineCta
            className={css.link}
            textPreset={ctaPreset ?? GlobalTextPreset.Cta12_14HafferSemiBold}
            theme={theme}
            withoutTextStyle={withoutCtaTextStyle}
            key={`rich-text-link-${attribs?.href}`}
            hasLine={hasLine}
            onClick={onClick}
            href={isWrongUrlResult ? null : attribs?.href}>
            {domToReact(children)}
          </InlineCta>
        )
      }

      // Shopify's translation add useless <a> and destroy the front
      // replace the <a> useless by a <p>
      // add display inline to follow the style
      if (type === 'tag' && name === 'a' && !hasLinkHref) {
        return <p style={{ display: 'inline' }}>{domToReact(children)}</p>
      }
    },
  }

  const html = parse(
    convertSchemaToHtml(render),
    options as HTMLReactParserOptions,
  )

  return html
}
