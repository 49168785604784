import classnames from 'classnames/bind'
import React, { useMemo, useRef } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import { ArrowLeftIcon, ArrowRightIcon } from '~/components/Icons'
import ProductCard, { ProductCardProps } from '~/components/Slices/ProductCard'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import useTrackingProductImpression from '~/hooks/useTrackingProductImpresion'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface SliderNavigationProps {
  className?: string
  onPrev(): void
  onNext(): void
  isPrevVisible?: boolean
  isNextVisible?: boolean
  hasSeparator?: boolean
  iconClassName?: string
}

export interface ProductsSliderProps {
  className?: string
  title?: RichTextBlocks
  items?: ProductCardProps[]
}

export function SliderNavigation({
  className,
  iconClassName,
  onNext,
  onPrev,
  isPrevVisible,
  isNextVisible,
  hasSeparator = true,
}: SliderNavigationProps) {
  return (
    <div className={cx(css.SliderNavigation, className)}>
      <ArrowLeftIcon
        className={cx(
          css.icon,
          css.prev,
          { isVisible: isPrevVisible },
          iconClassName,
        )}
        onClick={onPrev}
      />

      {hasSeparator && <span className={cx(css.separator)} />}

      <ArrowRightIcon
        className={cx(
          css.icon,
          css.next,
          { isVisible: isNextVisible },
          iconClassName,
        )}
        onClick={onNext}
      />
    </div>
  )
}

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 6 / 24 }, { ratio: 12 / 24 }]

function ProductsSlider({ className, title, items }: ProductsSliderProps) {
  const customSliderRef = useRef()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title30_60HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const [
    { nextSlide, prevSlide, slideIndex, enableDrag, maxSlideIndex },
    setSliderState,
  ] = useSliderState()

  const hasSlider = items && items.length > 0

  const { isPrevVisible, isNextVisible } = useMemo(
    () => ({
      isPrevVisible: enableDrag && slideIndex !== 0,
      isNextVisible: enableDrag && slideIndex < maxSlideIndex - 1,
    }),
    [enableDrag, maxSlideIndex, slideIndex],
  )

  const { ref } = useTrackingProductImpression(items)

  const { position: slicePosition } = useSliceProvider()

  return items?.length > 0 ? (
    <div ref={ref} className={cx(css.ProductsSlider, className)}>
      <div className={cx(css.container, gridStyle)}>
        <div className={cx(css.header)}>
          <RichText className={cx(css.title, titleStyle)} render={title} />

          {hasSlider && items.length > 4 && (
            <SliderNavigation
              className={cx(css.navigation)}
              onPrev={prevSlide}
              onNext={nextSlide}
              isPrevVisible={isPrevVisible}
              isNextVisible={isNextVisible}
            />
          )}
        </div>

        {hasSlider && (
          <Slider
            className={css.slider}
            setSliderState={setSliderState}
            customSliderRef={customSliderRef}
            autoBlockSlideIndexChange={true}
            snap>
            {items?.map((card, index) => (
              <ProductCard
                key={`${card.name}_${index}`}
                className={cx(css.card, css.cardWidth)}
                sizesFromBreakpoints={IMAGE_SIZES}
                priority={slicePosition === 1 && index < 5}
                {...card}
              />
            ))}
          </Slider>
        )}
      </div>
    </div>
  ) : null
}

ProductsSlider.defaultProps = {}

export default ProductsSlider
