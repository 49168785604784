import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useMemo } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalBreakpoints } from '~/@types/breakpoints'
import { CardProduct } from '~/@types/card'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { TRACKING_BRAND, TRACKING_EVENTS } from '~/lib/constants'
import { SHOPIFY_TYPES } from '~/lib/shopify-types'

import { useIsHover } from '@unlikelystudio/react-hooks'
import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import ShopifyRichText from '~/components/Abstracts/ShopifyRichText'
import { PRODUCT_TYPE_TRACKING } from '~/components/Panels/CartPanel/CartContent/types'
import { SliderNavigation } from '~/components/Slices/ProductsSlider'
import ColorsProduct, {
  ColorsProductProps,
} from '~/components/UI/ColorsProduct'
import { DiscountBadge } from '~/components/UI/DiscountBadge'
import Image from '~/components/UI/Image'
import Intensity, { IntensityProps } from '~/components/UI/Intensity'
import Link from '~/components/UI/Link'
import VariantsLabels from '~/components/UI/VariantsLabels'

import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import {
  serializeTrackingProduct,
  TrackingProductData,
} from '~/hooks/tracking/useGTMTracking'
import {
  getDiscountPercentOfProduct,
  getDiscountValueOfProduct,
  getGTMTypeFromPrismicType,
  getNonDiscountedPriceProduct,
} from '~/hooks/tracking/utils'
import useBreakpoint from '~/hooks/useBreakpoint'
import useCurrency from '~/hooks/useCurrency'
import useShop from '~/hooks/useShop'

import { getCurrentColor } from '~/utils/get-current-color'

import { PRODUCT } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ProductCardProps extends CardProduct {
  intensity?: IntensityProps['intensity']
  colors?: ColorsProductProps['colors']
  trackingGTMData?: TrackingProductData
  productType?: string
  cardWidthClassName?: string
  hideVariants?: boolean
  priority?: boolean
}

const IMAGE_SIZES = {
  DEFAULT: [{ ratio: 24 / 24 }],
}

function ProductCard({
  className,
  cardWidthClassName,
  image,
  imageHover,
  name,
  label,
  price,
  computedDiscount,
  compareAtPrice,
  link,
  intensity,
  colors,
  sizesFromBreakpoints,
  ratio,
  pin,
  type,
  trackingGTMData,
  defaultVariant,
  variants,
  hideVariants,
  priority,
}: ProductCardProps) {
  const { tracker } = useTracker()
  const isMobile = useBreakpoint(GlobalBreakpoints.MD)
  const currency = useCurrency()
  const t = useTranslate()
  const [isHover, callbacks] = useIsHover()

  const nameStyle = useStyle({
    textPreset: GlobalTextPreset.Title10_14HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Label10_14Haffer,
    color: GlobalThemeColors.DoveGray,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const priceStyle = useStyle({
    textPreset: GlobalTextPreset.Label10_14Haffer,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const pinStyle = useStyle({
    textPreset: GlobalTextPreset.Label10_12HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const shop = useShop(type)

  const isFashionProduct = type === SHOPIFY_TYPES.PRODUCT_FASHION
  const isFragranceProduct = type === SHOPIFY_TYPES.PRODUCT_FRAGRANCE

  const handleClick = () => {
    tracker.emit(TRACKING_EVENTS.PRODUCT_CLICK, {
      eventLabel: name,
      ecommerce: {
        click: {
          actionField: {
            list: trackingGTMData?.list,
          },
          currencyCode: currency,
          products: [
            serializeTrackingProduct({
              id: defaultVariant?.barcode?.toString() ?? null,
              name,
              price: defaultVariant?.priceAmount?.toString() ?? null,
              brand: TRACKING_BRAND,
              // re-enable it later
              // category: productType ?? null,
              category: getGTMTypeFromPrismicType(type),
              type: getGTMTypeFromPrismicType(type),
              color: getCurrentColor(colors)?.name,
              discountPercentage: getDiscountPercentOfProduct(defaultVariant),
              discount: getDiscountValueOfProduct(defaultVariant),
              catalogPrice: getNonDiscountedPriceProduct(defaultVariant),
              productType: PRODUCT_TYPE_TRACKING.PRODUCT,
              ...trackingGTMData,
            }),
          ],
        },
      },
    })
  }

  const images = [image, imageHover]?.filter(Boolean)

  const [
    { slideIndex, maxSlideIndex, enableDrag, nextSlide, prevSlide },
    setSliderState,
  ] = useSliderState()

  const { isPrevVisible, isNextVisible } = useMemo(
    () => ({
      isPrevVisible: enableDrag && slideIndex !== 0,
      isNextVisible: enableDrag && slideIndex < maxSlideIndex,
    }),
    [enableDrag, maxSlideIndex, slideIndex],
  )

  const defaultCards = (
    <>
      {image && (
        <Image
          draggable="false"
          layout="fill"
          objectFit="cover"
          className={cx(css.image)}
          ratio={ratio ?? css.ratio}
          sizesFromBreakpoints={sizesFromBreakpoints ?? IMAGE_SIZES.DEFAULT}
          asPlaceholder
          priority={priority}
          {...image}
        />
      )}
      {imageHover && (
        <AnimatePresence>
          {isHover && (
            <m.div
              className={cx(css.imageHover)}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.25 },
              }}
              exit={{
                opacity: 0,
                transition: { duration: 0.25 },
              }}>
              <Image
                draggable="false"
                layout="fill"
                objectFit="cover"
                ratio={ratio ?? css.ratio}
                sizesFromBreakpoints={
                  sizesFromBreakpoints ?? IMAGE_SIZES.DEFAULT
                }
                {...imageHover}
              />
            </m.div>
          )}
        </AnimatePresence>
      )}
    </>
  )

  const sliderLayout = (
    <>
      <div className={css.hideOnSmallScreen}>{defaultCards}</div>
      <div className={css.hideOnLargeScreen}>
        {images?.length > 1 ? (
          <>
            <Slider
              className={css.slider}
              snap
              infinite={true}
              maxSlideIndexChange={1}
              setSliderState={setSliderState}>
              {images?.map((image, index) => (
                <Image
                  key={`image_${index}`}
                  draggable="false"
                  layout="fill"
                  objectFit="cover"
                  className={cx(css.image, cardWidthClassName)}
                  ratio={ratio ?? css.ratio}
                  sizesFromBreakpoints={
                    sizesFromBreakpoints ?? IMAGE_SIZES.DEFAULT
                  }
                  asPlaceholder
                  {...image}
                />
              ))}
            </Slider>
            {images?.length > 1 && (
              <SliderNavigation
                className={cx(css.sliderNavigation)}
                iconClassName={css.sliderIcon}
                isPrevVisible={isPrevVisible}
                isNextVisible={isNextVisible}
                onPrev={prevSlide}
                onNext={nextSlide}
                hasSeparator={false}
              />
            )}
          </>
        ) : (
          <Image
            draggable="false"
            layout="fill"
            objectFit="cover"
            className={cx(css.image)}
            ratio={ratio ?? css.ratio}
            sizesFromBreakpoints={sizesFromBreakpoints ?? IMAGE_SIZES.DEFAULT}
            asPlaceholder
            {...image}
          />
        )}
      </div>
    </>
  )

  return (
    <div
      className={cx(css.ProductCard, className, cardWidthClassName)}
      {...callbacks}>
      <Link {...link} onClick={handleClick} className={css.cardWrapper}>
        <div className={css.images}>
          {cardWidthClassName ? sliderLayout : defaultCards}

          <div className={cx(css.cardInfos)}>
            {!colors && intensity && isFragranceProduct && (
              <Intensity
                visible={isHover || isMobile}
                className={cx(css.cardInfosItem, css.intensity)}
                intensity={intensity}
              />
            )}

            {
              // Hide if non ecom
              shop &&
                !hideVariants &&
                // Hide if only one variant (eg: TU)
                variants &&
                variants?.length > 1 &&
                isFashionProduct && (
                  <VariantsLabels
                    visible={isHover || isMobile}
                    className={cx(css.cardInfosItem, css.variantsLabels)}
                    variants={variants}
                  />
                )
            }
          </div>
        </div>
        <div className={css.texts}>
          <div className={css.topText}>
            {name && <h3 className={cx(css.name, nameStyle)}>{name}</h3>}
            <ShopifyRichText
              className={cx(css.label, labelStyle)}
              render={label}
            />
          </div>

          {
            // Hide if non ecom
            shop && (
              <div className={css.prices}>
                {compareAtPrice && (
                  <p className={cx(css.compareAtPrice, priceStyle)}>
                    {compareAtPrice}
                  </p>
                )}
                {price && (
                  <div className={cx(css.price, priceStyle)}>
                    {variants?.length > 1 && !isFashionProduct
                      ? `${t(PRODUCT.FROM)} `
                      : ''}
                    {price}
                  </div>
                )}
              </div>
            )
          }
        </div>
      </Link>

      {!intensity && colors && (
        <ColorsProduct
          visible={isHover || isMobile}
          className={cx(css.cardInfosItem, css.colorsProduct)}
          colors={colors}
        />
      )}

      <ShopifyRichText className={cx(css.pin, pinStyle)} render={pin} />
      {computedDiscount && (
        <DiscountBadge className={css.discountBadge} value={computedDiscount} />
      )}
    </div>
  )
}

ProductCard.defaultProps = {}

export default ProductCard
