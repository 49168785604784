import classnames from 'classnames'
import { forwardRef } from 'react'

import { HtmlSerializeProps } from '~/components/Abstracts/RichText/get-html-serializer'
import getHtmlSerializer from '~/components/Abstracts/ShopifyRichText/get-html-serializer'
import {
  InlineCtaColors,
  InlineCtaProps,
  InlineCtaTextPreset,
} from '~/components/UI/InlineCta'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ShopifyRichTextBlockChildren = {
  type: string
  value: string
}

export type ShopifyRichTextBlock = {
  type: string
  children?: ShopifyRichTextBlock[]
  value?: string
}

export type ShopifyRichTextBlocks = ShopifyRichTextBlock

export type ShopifyRichTextProps = {
  render: ShopifyRichTextBlocks | string
  className?: string
  innerRef?: any
  ctaPreset?: InlineCtaTextPreset
  theme?: InlineCtaColors
  withoutCtaTextStyle?: InlineCtaProps['withoutTextStyle']
  hasLine?: InlineCtaProps['hasLine']
  uppercaseLink?: boolean
  onClick?: HtmlSerializeProps['onClick']
}

function ShopifyRichText({
  className,
  render,
  innerRef,
  ctaPreset,
  theme,
  hasLine,
  withoutCtaTextStyle = false,
  uppercaseLink = true,
  onClick,
}: ShopifyRichTextProps) {
  if (!render) {
    return null
  }

  const serializedContent =
    typeof render === 'string'
      ? render
      : getHtmlSerializer({
          ctaPreset,
          theme,
          withoutCtaTextStyle,
          hasLine,
          onClick,
          render: render as ShopifyRichTextBlocks,
        })

  const isAlreadyHTML = typeof serializedContent === 'string'

  const WYSIWYGProps = {
    ...(isAlreadyHTML
      ? { dangerouslySetInnerHTML: { __html: serializedContent as string } }
      : { children: serializedContent }),
  }

  return render ? (
    <div
      className={cx(className, css.RichText, { uppercaseLink })}
      ref={innerRef}
      {...WYSIWYGProps}
    />
  ) : null
}

function ShopifyRichTextForwarded(props: ShopifyRichTextProps, ref) {
  return <ShopifyRichText innerRef={ref} {...props} />
}

export default forwardRef<HTMLDivElement, ShopifyRichTextProps>(
  ShopifyRichTextForwarded,
)
