import classnames from 'classnames/bind'
import React from 'react'
import { CardProduct } from '~/@types/card'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface VariantsLabelsProps {
  className?: string
  label?: string
  variants?: CardProduct['variants']
  visible?: boolean
}

function VariantsLabels({ className, variants, visible }: VariantsLabelsProps) {
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Label10_12Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={cx(css.VariantsLabels, className, { visible })}>
      {variants?.map((variant, index) => {
        return (
          <span
            className={cx(labelStyle, css.label, {
              available: variant?.quantityAvailable > 0,
            })}
            key={`variant_${index}`}
          >
            {variant?.title}
          </span>
        )
      })}
    </div>
  )
}

VariantsLabels.defaultProps = {}

export default VariantsLabels
