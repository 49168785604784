import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import Link, { LinkProps } from '~/components/UI/Link'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ColorProduct {
  color?: string
  name?: string
  link?: LinkProps
  uid?: string
  current?: boolean
  hasBorder?: boolean
  index?: number
  currentIndex?: number
  onMouseEnter?(index): void
}

export interface ColorsProductProps {
  className?: string
  colors?: ColorProduct[]
  max?: number
  visible?: boolean
}

function ColorsProduct({
  className,
  colors,
  visible,
  max = 3,
}: ColorsProductProps) {
  const restStyle = useStyle({
    textPreset: GlobalTextPreset.Label10_12Haffer,
    color: GlobalThemeColors.DoveGray,
  })

  const rest = colors?.length - max

  return colors?.length > 0 ? (
    <div className={cx(css.ColorsProduct, className, { visible })}>
      {colors?.map(
        ({ color, link, hasBorder, name }, index) =>
          index < max && (
            <Link
              className={cx(css.dot)}
              {...link}
              key={`color_item_${index}`}
              aria-label={name}
            >
              <span
                className={cx(css.dotContent, { hasBorder })}
                style={{ backgroundColor: color }}
              >
                {link?.children}
              </span>
            </Link>
          ),
      )}
      {rest > 0 && <span className={cx(css.rest, restStyle)}>+{rest}</span>}
    </div>
  ) : null
}

ColorsProduct.defaultProps = {
  visible: true,
}

export default ColorsProduct
